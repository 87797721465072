jQuery.validateEmail = function (email) {
    er = /^[a-zA-Z0-9][a-zA-Z0-9\._-]+@([a-zA-Z0-9\._-]+\.)[a-zA-Z-0-9]{2}/;
    if (er.exec(email))
        return true;
    else
        return false;
};

jQuery.fn.validaCampo = function (msg, alerta) {
    var valor = jQuery(this).val();
    if ((/^ *$/.test(valor)) || (valor === null)) {
        // string is not empty and not just whitespace
        jQuery("#" + alerta + "-msg").html(msg);
        jQuery("#" + alerta).css('display', 'block');
        jQuery(this).focus();
        return false;
    }
    return true;
};

jQuery.fn.validaEmail = function (alerta) {
    if (/^ *$/.test(jQuery(this).val())) {
        // string is not empty and not just whitespace
        jQuery("#" + alerta + "-msg").html("É necessário preencher o campo E-mail.");
        jQuery("#" + alerta).css('display', 'block');
        jQuery(this).focus();
        return false;
    } else {
        if (!jQuery.validateEmail(jQuery(this).val())) {
            jQuery("#" + alerta + "-msg").html("O E-mail digitado é inválido.");
            jQuery("#" + alerta).css('display', 'block');
            jQuery(this).focus();
            return false;
        }
    }
    return true;
};

jQuery(function(){
	jQuery(document).ready(function(){
        jQuery('.data').mask("99/99/9999");
        jQuery('.hora').mask("99:99");
        jQuery('.cep').mask("99.999-999");
        jQuery('.cpf').mask("999.999.999-99");
        jQuery('.cnpj').mask("99.999.999/9999-99");
        jQuery('.telefone').focusout(function () {
            var phone, element;
            element = jQuery(this);
            element.unmask();
            phone = element.val().replace(/\D/g, '');
            if (phone.length > 10) {
                element.mask("(99) 99999-999?9");
            } else {
                element.mask("(99) 9999-9999?9");
            }
        }).trigger('focusout');
    });


	jQuery(".btn-contato").click(function(e){
        e.preventDefault();
        
        if (!jQuery("input[name=nome]").validaCampo("É necessário preencher o campo Nome.", 'alerta')) { return false;}
        if (!jQuery("input[name=email]").validaEmail('alerta')) { return false;}
        if (!jQuery("select[name=destino]").validaCampo("É necessário selecionar o Destino da mensagem.", 'alerta')) { return false;}
        if (!jQuery("textarea[name=mensagem]").validaCampo("É necessário preencher o campo Mensagem.", 'alerta')) { return false;}
        
        jQuery("#form-contato").submit();
    });

    jQuery(".btn-trabalhe").click(function(e){
        e.preventDefault();
        
        if (!jQuery("input[name=nome]").validaCampo("É necessário preencher o campo Nome.", 'alerta')) { return false;}
        if (!jQuery("input[name=email]").validaEmail('alerta')) { return false;}
        if (!jQuery("input[name=curriculo]").validaCampo("É necessário anexar o arquivo do Currículo.", 'alerta')) { return false;}
        
        jQuery("#form-trabalhe").submit();
    });

});